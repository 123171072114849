import React, {Component} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import Home from "./pages/Home";
import Chart from "./pages/Chart";
import About from "./pages/About";

class App extends Component {
    render() {
            return (
                <BrowserRouter>
                    <Route path="//" component={Home}/>
                    <Route path="/about" component={About}/>
                    <Route path="/chart" component={Chart}/>
                </BrowserRouter>

            )
        }


}


export default App
