import React, {Component} from "react";


class Value extends Component {
    render() {
        return (
            <div className="value">
                <div className="type">
                    <hr className="no-padding no-margin"/>
                    <div className="row l_h_50 currency_row">
                        <div className="col-2 d-flex align-items-center paddingRight0" ><img src={"/images/flags/" + this.props.type + ".png"} className="paddingRight0" alt={this.props.typeIcon} width="45"
                                                                          height="30"/></div>
                        <div className="col-2"><em>{this.props.type.toUpperCase()}</em></div>
                        <div className="col-2">{parseFloat(this.props.buy).toFixed(2)}</div>
                        <div className="col-2">{parseFloat(this.props.sell).toFixed(2)}</div>
                        <div className="col-2 small_font weight_normal">{((parseFloat(this.props.sell) + parseFloat(this.props.buy)) / 2).toFixed(2)}</div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Value