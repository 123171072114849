import React, {Component} from "react";


class Site extends Component {
    render() {
        return (
            <div className="site">
                <div
                    className={"rounded-circle img-responsive img-center justify-content-center d-flex align-items-center div_logo_wrapper " + this.props.name.replace(' ','').toLowerCase() + "_logo"}>
                    <a href={this.props.site} target="_blank" rel="noopener noreferrer"><img
                        className="img-responsive img-center"
                        src={this.props.logo}
                        alt={this.props.name}/></a>
                </div>
                <div className="text-center">
                    <a href="#" data-toggle="modal" data-target="#onMapKantor">На карті</a>
                    <h4><a href={this.props.site}
                           target="_blank" rel="noopener noreferrer">{this.props.name}</a></h4>
                </div>
                <div className="text-center  "><h5>Станом на {this.props.lastUpdate}</h5></div>
                <div className="row this.propsuta_header">
                    <div className="col-4 text-center bold">Вид валюти</div>
                    <div className="col-2 text-center bold ">Купівля</div>
                    <div className="col-2 text-center bold ">Продаж</div>
                    <div className="col-2 text-center bold ">Середній</div>
                </div>
                {this.props.children}

            </div>
        )
    }


}

export default Site