import React, {Component} from "react";


class Info extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-8 ">
                        <h2>Інформація про ресурс</h2>
                        <p className="text-justify">Цей сайт створений для легкого відслідковування курсів валют у
                            найпопулярніших
                            обмінних пунктах Луцька. Працюючи над проектом, ми намагаємося зробити його зручним,
                            простим і
                            компактним. <a id="readMore" onClick={function () {
                                document.getElementById("about_more").style.display = "block";
                                document.getElementById("readMore").style.display = "none";
                            }}>Читати
                                далі...</a></p>

                        <div id="about_more" style={{display: "none"}} className="text-justify">Надзвичайно приємним є
                            той факт, що ресурс
                            набув чималої популярності серед лучан. Наразі щодоби курсом валют в обмінниках,
                            представлених на нашому
                            сайті, цікавиться понад 5 тисяч осіб! І ця цифра невпинно зростає!
                            <p>Безумовним стимулом для удосконалення проекту є ваші позитивні відгуки. Маємо зараз
                                багато
                                прихильників, критиків і конкурентів, що також дуже надихає розвивати ресурс, аби
                                надавати
                                користувачу інформацію якомога зручнішим чином.
                                Успіх і популярність нашого сайту можуть стати у нагоді й вашому бізнесу.</p>
                            <p><b>Матимемо за честь бути вашим партнером і допомогти у розвитку вашої справи.
                                Усі пропозиції, цікаві ідеї, ініціативи, а також питання з приводу розміщення
                                рекламної інформації
                                наша команда приймає на електронну пошту: <a
                                    href="mailto:info@kurs.lutsk.ua">info@kurs.lutsk.ua</a>.</b>
                            </p></div>

                    </div>
                    <div className="col-sm-4">
                        <h2>Зв'язок</h2>
                        <address>
                            <abbr title="Email">E-mail:</abbr> <a
                            href="mailto:info@kurs.lutsk.ua">info@kurs.lutsk.ua</a><br/>
                            <a href="#" id="contact-dialog-btn">Зворотній зв'язок</a>
                        </address>
                    </div>
                </div>
            </div>
        )
    }
}

export default Info