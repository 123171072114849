import React, {Component} from "react";
import Container from "../UI/Container";
import Chart from "react-google-charts";

class Charts extends Component {
    render() {
        return (
            <Container>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-4">
                            <select className="form-control" id="datetime" onChange="refreshChart()">

                            </select>
                        </div>
                        <div className="col-sm-4">
                            <select className="form-control" id="buy_sell" onChange="refreshChart()">
                                <option selected="" value="currency_value_buy">Купівля валюти</option>
                                <option value="currency_value_sell">Продаж валюти</option>
                            </select>
                        </div>
                        <div className="col-sm-4">
                            <select className="form-control" id="datetime" onChange="refreshChart()">
                                <option selected="" value="usd">Американський Доллар</option>
                                <option value="eur">Євро</option>
                                <option value="rur">Російський Рубль</option>
                                <option value="gbp">Фунт Стерлінгів</option>
                                <option value="chf">Швейцарський Франк</option>
                                <option value="pln">Польський Злотий</option>
                            </select>
                        </div>
                    </div>
                    <br/>
                    <Chart
                        width={'100%'}
                        height={'450px'}
                        chartType="LineChart"
                        loader={<div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        data={[
                            [
                                'Sell',
                                'Kantor',
                                'Goverla',
                                'Dima',
                            ],
                            ["2020-02-01", 37.8, 80.8, 41.8],
                            ["2020-02-02", 30.9, 69.5, 32.4],
                            ["2020-02-03", 25.4, 57, 25.7],
                            ["2020-02-04", 11.7, 18.8, 10.5],
                            ["2020-02-05", 11.9, 17.6, 10.4],
                            ["2020-02-06", 8.8, 13.6, 7.7],
                            ["2020-02-07", 7.6, 12.3, 9.6],
                            ["2020-02-08", 12.3, 29.2, 10.6],
                            ["2020-02-09", 16.9, 42.9, 14.8],
                            ["2020-02-10", 12.8, 30.9, 11.6],
                            ["2020-02-11", 5.3, 7.9, 4.7],
                            ["2020-02-12", 6.6, 8.4, 5.2],
                            ["2020-02-13", 4.8, 6.3, 3.6],
                            ["2020-02-14", 4.2, 6.2, 3.4],
                        ]}
                        options={{
                            title: "February",
                            hAxis: {
                                title: 'Days',
                                titleTextStyle: {color: '#fff'},
                                gridlines: {count: 7},
                                format: 'dd MMMM',
                            },
                            pointSize: 3,
                        }}
                        rootProps={{'data-testid': '3'}}
                    />
                    <hr/>
                </div>
            </Container>
        )
    }
}

export default Charts