import React, {Component} from "react";
import Container from "../UI/Container";


class About extends Component {
    render() {
        return (
            <Container>
                <p>
                    Ми - компанія ентузіастів, яким набридло порівнювати курси валют на різних сайтах.
                </p>
                <p>
                    Тому створили цей сайт, щоб полегшити життя Вам і собі.
                </p>
                <p>
                    Якщо у Вас є побажання чи пропозиції, надсилайте їх нам через Зворотній зв'язок або на електронну
                    пошту.
                </p>
            </Container>
        )
    }
}

export default About