import React, {Component} from "react";


class LastUpdate extends Component {
    render() {
        return (
            <div className="lastUpdate">
                <hr/>
                <hr/>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h3>{"Останнє оновлення "}</h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default LastUpdate