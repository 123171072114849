import React, {Component} from "react";


class Row extends Component{
    render() {
        return(
            <div className="row d-flex justify-content-center">
                {this.props.children}
            </div>
        )
    }
}

export default Row