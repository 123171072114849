import React, {Component} from "react";
import Container from "../UI/Container";
import Info from "../UI/Info";
import Row from "../UI/Row";
import Site from "../Exchanger/Site";
import Value from "../Exchanger/Value";
import LastUpdate from "../Exchanger/LastUpdate";

var conf = require('../config/config');

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
        };
    }

    componentDidMount() {
        fetch(conf.api.currency_types)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isTypesLoaded: true,
                        vals: result
                    });
                },
                (error) => {
                    this.setState({
                        isTypesLoaded: true,
                        error
                    });
                }
            );
        fetch(conf.api.currency_sites)
            .then(res => res.json())
            .then(
                (result) => {
                    let sortedResult = [];
                    sortedResult.push(result);

                    this.setState({
                        isLoaded: true,
                        items: sortedResult
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    formatDate(string){
        return new Date(string).toLocaleDateString();
    }

    getTypeById(types, id){
        var type;
        types.forEach(function (val) {
            if (val.currency_type_id === id) type = val;
        });
        return type;
    }
    render() {
        const {error, isLoaded, isTypesLoaded, items, vals} = this.state;
        if (error) {
            return <div>Ошибка: {error.message}</div>;
        } else if (!isLoaded || !isTypesLoaded) {
            return <div className="d-flex justify-content-center">
                <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>;
        } else {
        return (
            <div>
                <Container>
                    <Info/>
                </Container>
                <Container>
                    {items.map((line, lineKey) => (
                        <Row key={lineKey}>
                            {line.map((val, valKey) => (
                                <div className="col-lg-4 col-md-6" key={valKey}>
                                    <Site site={val.currency_site_http} logo={conf.media_domain + val.currency_site_logo}
                                          name={val.currency_site_name} lastUpdate={this.formatDate(val.site_last_update)}>
                                        {val.latestCurrencyValues.sort((a, b) => a.currency_type_id - b.currency_type_id).map((value, valueKey) => (
                                            <Value key={valueKey}
                                                   type={this.getTypeById(vals, value.currency_type_id).currency_type_code}
                                                   typeIcon="icon.png"
                                                   sell={value.currency_value_sell} buy={value.currency_value_buy}>

                                            </Value>
                                        ))}
                                    </Site>
                                </div>
                            ))}
                        </Row>
                    ))}
                    <LastUpdate/>
                </Container>
            </div>
        )
    }}
}

export default Home